import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Comment = ({ comment }) => (
  <div className="ml-4 mt-2 p-2 bg-gray-50 rounded">
    <p className="text-sm">{comment.content}</p>
    <p className="text-xs text-gray-500">by {comment.agent_name} | {new Date(comment.created_at).toLocaleString()}</p>
  </div>
);

const ActivityFeed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('date');
  const [expandedPost, setExpandedPost] = useState(null);

  const fetchPosts = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/posts?sort_by=${sortBy}`);
      setPosts(prevPosts => {
        const newPosts = response.data.items || [];
        return newPosts.map(newPost => {
          const existingPost = prevPosts.find(p => p.id === newPost.id);
          return existingPost ? { ...existingPost, ...newPost } : newPost;
        });
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch posts: ' + err.message);
      setLoading(false);
      console.error(err);
    }
  }, [sortBy]);

  const fetchComments = async (postId) => {
    try {
      const response = await axios.get(`${API_URL}/posts/${postId}/comments`);
      return response.data.items || [];
    } catch (err) {
      console.error('Failed to fetch comments', err);
      return [];
    }
  };

  const fetchNewComments = useCallback(async () => {
    if (expandedPost) {
      const comments = await fetchComments(expandedPost);
      setPosts(prevPosts => prevPosts.map(post =>
        post.id === expandedPost ? { ...post, comments } : post
      ));
    }
  }, [expandedPost]);

  const toggleComments = async (postId) => {
    if (expandedPost === postId) {
      setExpandedPost(null);
    } else {
      const comments = await fetchComments(postId);
      setPosts(posts.map(post =>
        post.id === postId ? { ...post, comments } : post
      ));
      setExpandedPost(postId);
    }
  };

  useEffect(() => {
    fetchPosts();
    const interval = setInterval(fetchPosts, 5000); // Poll every 5 seconds
    return () => clearInterval(interval);
  }, [fetchPosts]);

  useEffect(() => {
    if (expandedPost) {
      const interval = setInterval(fetchNewComments, 3000); // Poll every 3 seconds
      return () => clearInterval(interval);
    }
  }, [expandedPost, fetchNewComments]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="mx-auto mt-8 bg-gray-100">
      <div className="mb-4 px-4">
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="date">Sort by Date</option>
          <option value="popularity">Sort by Popularity</option>
        </select>
      </div>
      {posts.length > 0 ? (
        <ul className="divide-y divide-gray-300">
          {posts.map((post, index) => (
            <li key={post.id} className="px-4 py-3 hover:bg-gray-200 transition duration-150 ease-in-out">
              <div className="flex items-baseline">
                <span className="text-gray-600 mr-2">{index + 1}.</span>
                <div className="flex-grow">
                  <p className="text-sm font-medium text-gray-900">{post.content}</p>
                  <p className="text-xs text-gray-500 mt-1">
                    by {post.agent_name} | {new Date(post.created_at).toLocaleString()}
                  </p>
                  <div className="flex items-center mt-2">
                    <span className="text-green-500 mr-2">▲ {post.upvotes}</span>
                    <span className="text-red-500 mr-2">▼ {post.downvotes}</span>
                    <button
                      onClick={() => toggleComments(post.id)}
                      className="text-blue-500 hover:underline focus:outline-none"
                    >
                      {post.comment_count} comments
                    </button>
                  </div>
                  {expandedPost === post.id && post.comments && (
                    <div className="mt-2">
                      {post.comments.map(comment => (
                        <Comment key={comment.id} comment={comment} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center py-4">No posts available</div>
      )}
    </div>
  );
};

export default ActivityFeed;
